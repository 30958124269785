<template>
    <div class="page page-bg">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :left-text="$t('设置交易密码')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper-bg">
            <div class="bind-panel">
                <van-form class="bs-form" v-if="withdraw_info.pay_flag == 1">
                    <van-field type="password" v-model="old_password" :label="$t('原密码')" :placeholder="$t('请输入原密码')" maxlength="6" />
                    <van-field type="password" v-model="new_password" :label="$t('新交易密码')" :placeholder="$t('请输入新交易密码')" maxlength="6" />
                    <van-field type="password" v-model="q_password" :label="$t('确认交易密码')" :placeholder="$t('请输入确认交易密码')" maxlength="6" />
                </van-form>
                <van-form class="bs-form" v-else>
                    <!-- <van-field type="password" v-model="password" :label="$t('登录密码')" :placeholder="$t('请输入登录密码')" /> -->
                    <van-field type="password" v-model="new_password" :label="$t('交易密码')" :placeholder="$t('请输入交易密码')" maxlength="6" />
                    <van-field type="password" v-model="q_password" :label="$t('确认交易密码')" :placeholder="$t('请输入确认交易密码')" maxlength="6" />
                </van-form>
            </div>
            <div style="padding: 10px;font-size: 14px;color: #cccccc">
                <span style="color: #cccccc">{{$t('交易密码为6位数字密码')}}</span>
            </div>
        </div>

        <div class="btn-foot-space">
            <van-button  block color="linear-gradient(to right, #3270e0, #3653ea)" @click="updatePwd">{{$t('确认')}}</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                show: false,
                password: '',
                old_password: '',
                new_password: '',
                q_password: '',
                withdraw_info: {},
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getWithdrawInfo() {
                this.$axios
                    .get(this.$api_url.withdraw_info)
                    .then((res) => {
                        this.withdraw_info = res.data;
                    })
            },
            updatePwd() {
                if(this.withdraw_info.pay_flag == 1){
                    if (this.old_password == '') {
                        this.$toast.fail(this.$t('请输入原始密码'));
                        return;
                    }
                }

                if(this.new_password == ''){
                    this.$toast.fail(this.$t('请输入新密码'));
                    return;
                }
                if(this.q_password == ''){
                    this.$toast.fail(this.$t('请输入确认新密码'));
                    return;
                }
                if (this.new_password != this.q_password) {
                    this.$toast.fail(this.$t('两次密码不一致'));
                    return;
                }
                this.$axios.post(this.$api_url.save_pay_password,
                    {old_password: this.old_password, new_password: this.new_password, password:this.password}
                ).then((res) => {
                    this.$toast.success(res.data.msg);
                    this.$router.go(-1);
                }).catch((e) => {
                    this.$toast.fail(e.data.msg);
                })
            }
        },
        mounted() {
            // localStorage.setItem('aqzx', '1');
        },
        created() {
            this.getWithdrawInfo();
        }

    }
</script>